body{
  background-image: url('bg.jpg');
  background-size: cover;
  background-position: center;
}

.box-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40vh;
}

.container {
  background-color: white;
  width: 400px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  border-radius: 4px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;

}

.container h1 {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.container form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.input-container input[type="text"] {
  flex: 1;
  height: 40px;
  padding: 0 10px;
  font-size: 16px;
  margin-right: 10px;
}

.input-container button[type="submit"] {
  height: 40px;
  width: 100px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.container .error {
  color: #dc3545;
  margin-top: 15px;
  margin-bottom: 10px;
}

.container .message-box {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 21px;
  margin-left: 10%;
}

.container .shortened-url {
  margin-top: 20px;
}

.shortened-url .short-url-container {
  display: flex;
  align-items: center;
}

.shortened-url .short-url-container a{
  display: block;
  word-break: break-all;
  color: #007bff;
  text-decoration: none;
  margin-right: 10px;
}

.shortened-url .short-url-container .copy-icon{
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.shortened-url .short-url-container .copy-icon svg{
  width: 16px;
  height: 16px;
  stroke: currentColor;
  fill: none;
}

.container .shortened-url a {
  display: block;
  word-break: break-all;
  color: #007bff;
  text-decoration: none;
}

#donate {
  margin-top: 15px;
  color: black;
  font-weight: 500;
}