.footer {
    background-color: grey;
    color: white;
    padding: 15px;
    position: fixed;
    bottom: 0;
    width: 100%;
    font-size: 13px;
    text-align: center;
  }
  
  .footer-content {
    display: flex;    
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fotter-text {
    margin: 0;
  }

  .social-icons {
    padding-left: 10px;
  }

  .footer-content p {
    margin: 0;
    font-size: 16px;
  }
  
  .social-links {
    margin-top: 5px;
  }
  
  .social-links a {
    color: white;
    margin: 0 10px;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .social-links a:hover {
    color: #ddd;
  }
  